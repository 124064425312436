<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-64" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
        </colgroup>
      </template>
      <template v-slot:item-Naam="{ item }">
        <small v-if="!item.user_id" class="text-red-500">Geen chauffeur</small>
        <span v-else>{{ store.getters.chauffeur(item.user_id)?.label || item.user_id }}</span>
      </template>
      <template v-slot:item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template v-slot:item-Loon="{ item }">
        <span>{{ item.loon }}</span>
      </template>
      <template v-slot:item-Omzet="{ item }">
        <span>{{ item.omzet }}</span>
      </template>
      <template v-slot:item-Ritten="{ item }">
        <span>{{ item.ritten }}</span>
      </template>
      <template v-slot:item-Google_KMs="{ item }">
        <span>{{ item.loon_km }}</span>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { monthToString } from '@/functions/formatDate'
import useGetApi from '@/hooks/useGetApi'
import UITableResponsive from '@/components/UI/Table/Responsive'

const props = defineProps({
  filters: {
    type: Object,
    required: true,
  },
})

const store = useStore()
const headers = ['Datum', 'Naam', 'Loon', 'Omzet', 'Ritten', 'Google_KMs']

const { data } = useGetApi('/api/dashboard/stats/chauffeurs/lonen', props.filters, { watch: true })
</script>
